const GA_TAG_ID = process.env.REACT_APP_GA4_TAG_ID;

const getSessionData = async () => {
  if (typeof window.gtag !== "undefined") {
    const gclid = window.localStorage.getItem("gclid");
    const gtagGetPromise = new Promise(resolve => {
      window.gtag("get", GA_TAG_ID, "session_id", resolve);
    });

    const sessionId = await gtagGetPromise;

    const sessionData = {
      ga_session_id: sessionId,
      ...(gclid && { gclid })
    };

    return sessionData;
  }
  return {};
};

export default getSessionData;
