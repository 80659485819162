import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { reduxForm, Field, formValues } from "redux-form";
import { compose } from "recompose";
import { useSnackbar } from "notistack";
import InputAdornment from "@mui/material/InputAdornment";
import PropTypes from "prop-types";

import { CAR_SUMMARY } from "Queries/Car/Car";
import { OWNER_DEFAULT_PROTECTION_PLAN } from "Queries/Owner/OwnerQueries";
import { FLEET_UPDATE_CARS } from "Mutations/Owner/OwnerMutations";
import { FormTextField } from "Components/Inputs/FormTextField";
import { FormSelect } from "Components/Inputs/FormSelect";
import { DrawerExpansionPanel } from "Components/Drawers/DrawerExpansionPanel";
import { getEarnings } from "Utils/Helpers";
import {
  centsToDollars,
  dollarsToCents,
  calculateWeeklyDiscount,
  calculateMonthlyDiscount,
  calculateWeeklyPriceInCentsWithDiscount,
  calculateMonthlyPriceInCentsWithDiscount
} from "Utils/Calculations";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { UnsavedProgressDialogContext } from "Components/Utils/UnsavedProgressDialogProvider";
import Alert from "@mui/material/Alert";
import { OverpricedCarsContext } from "../../Utils/OverpricedCarsProvider";

const mileage_options = [
  { value: "250", label: "250 miles per day" },
  { value: "300", label: "300 miles per day" },
  { value: "350", label: "350 miles per day" },
  { value: "400", label: "400 miles per day" },
  { value: "450", label: "450 miles per day" },
  { value: "500", label: "500 miles per day" },
  { value: "550", label: "550 miles per day" },
  { value: "600", label: "600 miles per day" },
  { value: "650", label: "650 miles per day" },
  { value: "700", label: "700 miles per day" },
  { value: "750", label: "750 miles per day" },
  { value: "1000", label: "1000 miles per day" }
];

const validate = ({
  dailyRate,
  weeklyDiscount,
  monthlyDiscount,
  maxDailyMiles
}) => {
  const errors = {};
  if (dailyRate == null) {
    errors.dailyRate = "Required";
  } else if (dailyRate < 20) {
    errors.dailyRate = "Must be at least $20 per day.";
  } else if (dailyRate > 150) {
    errors.dailyRate = "The maximum daily listing price is $150.";
  }
  if (weeklyDiscount == null || weeklyDiscount === "") {
    errors.weeklyDiscount = "Required";
  } else if (weeklyDiscount < 0) {
    errors.weeklyDiscount = "Must be at least 0.";
  } else if (weeklyDiscount > 100) {
    errors.weeklyDiscount = "Must be less than 100.";
  }
  if (monthlyDiscount == null || monthlyDiscount === "") {
    errors.monthlyDiscount = "Required";
  } else if (monthlyDiscount < 0) {
    errors.monthlyDiscount = "Must be at least 0.";
  } else if (monthlyDiscount > 100) {
    errors.monthlyDiscount = "Must be less than 100.";
  }
  if (!maxDailyMiles) {
    errors.maxDailyMiles = "Required";
  } else if (maxDailyMiles < 250) {
    errors.maxDailyMiles = "Must be at least 250 miles per day";
  }

  return errors;
};

const normalize = (value, previousValue) => {
  const regex = /^[0-9]*$/i;
  if (regex.test(value)) {
    const parsed = parseInt(value);
    return isNaN(parsed) ? "" : parsed;
  }

  return previousValue;
};

const ListingTermsForm = compose(
  reduxForm({
    destroyOnUnmount: true,
    form: "LISTING_TERMS",
    onChange: (v, d, props) => props.setUnsavedProgressExistsState(props.dirty),
    onSubmitSuccess: (r, d, props) =>
      props.setUnsavedProgressExistsState(false),
    validate
  }),
  formValues({
    dailyRate: "dailyRate",
    weeklyDiscount: "weeklyDiscount",
    monthlyDiscount: "monthlyDiscount"
  })
)(
  ({
    handleSubmit,
    submitting,
    dailyRate,
    weeklyDiscount,
    monthlyDiscount,
    defaultProtectionPlan,
    setDisplayUnsavedProgressDialog,
    unsavedProgressExists,
    carOverpriceSummary,
    isActive,
    carYear,
    carMake,
    carModel
  }) => {
    const { netDailyPrice, weeklyEarnings, monthlyEarnings } = getEarnings({
      daily_rate: dailyRate,
      weekly_discount: weeklyDiscount,
      monthly_discount: monthlyDiscount,
      protection_plan: defaultProtectionPlan
    });

    return (
      <DrawerExpansionPanel
        onCancel={
          unsavedProgressExists
            ? () => setDisplayUnsavedProgressDialog(true)
            : undefined
        }
        onSubmit={handleSubmit}
        submitting={submitting}
        title="Pricing"
        defaultExpanded={isActive}
      >
        {carOverpriceSummary && carOverpriceSummary.overpriced && (
          <Alert severity="warning">
            Our data shows that setting the daily price between $
            {carOverpriceSummary.priceRecommendations.low} - $
            {carOverpriceSummary.priceRecommendations.high} can maximize your
            earnings for a {carYear} {carMake} {carModel}.
          </Alert>
        )}
        <Field
          name="dailyRate"
          label="Daily Price"
          variant="filled"
          fullWidth
          margin="normal"
          helperText={`Earn $${netDailyPrice} daily`}
          normalize={normalize}
          component={FormTextField}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
        <Field
          name="weeklyDiscount"
          label="Weekly Discount"
          variant="filled"
          fullWidth
          margin="normal"
          helperText={`Earn $${weeklyEarnings} weekly`}
          normalize={normalize}
          component={FormTextField}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>
          }}
        />
        <Field
          name="monthlyDiscount"
          label="Monthly Discount"
          variant="filled"
          fullWidth
          margin="normal"
          helperText={`Earn $${monthlyEarnings} monthly`}
          normalize={normalize}
          component={FormTextField}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>
          }}
        />
        <Field
          name="maxDailyMiles"
          label="Daily Allowed Miles"
          variant="filled"
          fullWidth
          margin="normal"
          component={FormSelect}
          options={mileage_options}
        />
      </DrawerExpansionPanel>
    );
  }
);

export const ListingTerms = ({ carId, isActive, carOverpriceSummary }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: paramsId } = useParams();
  const id = carId ? carId : paramsId;
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const {
    setDisplayUnsavedProgressDialog,
    setUnsavedProgressExistsState,
    unsavedProgressExists
  } = useContext(UnsavedProgressDialogContext);
  const { refetchCarSummary } = useContext(OverpricedCarsContext);

  const [updateCar] = useMutation(FLEET_UPDATE_CARS, {
    client: currentRooftopClient
  });
  const {
    data: { car }
  } = useQuery(CAR_SUMMARY, {
    variables: {
      id
    },
    fetchPolicy: "cache-only",
    client: currentRooftopClient
  });

  const { data } = useQuery(OWNER_DEFAULT_PROTECTION_PLAN, {
    fetchPolicy: "cache-only",
    client: currentRooftopClient
  });
  const owner = data?.viewer?.me?.owner;

  const onSubmit = async values => {
    try {
      const dailyPriceInCents = dollarsToCents(values.dailyRate);
      await updateCar({
        variables: {
          input: [
            {
              id,
              dailyPriceInCents,
              weeklyPriceInCents: calculateWeeklyPriceInCentsWithDiscount(
                dailyPriceInCents,
                values.weeklyDiscount
              ),
              monthlyPriceInCents: calculateMonthlyPriceInCentsWithDiscount(
                dailyPriceInCents,
                values.monthlyDiscount
              ),
              maxDailyMiles: values.maxDailyMiles
            }
          ]
        }
      });
      enqueueSnackbar("Successfully Updated Car", {
        variant: "success"
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Sorry, something went wrong. Please try again.", {
        variant: "error"
      });
    }
    await refetchCarSummary();
  };

  return (
    <ListingTermsForm
      onSubmit={onSubmit}
      defaultProtectionPlan={
        car.defaultProtectionPlan
          ? car.defaultProtectionPlan
          : owner.defaultProtectionPlan
      }
      initialValues={{
        maxDailyMiles: `${car.maxDailyMiles}`,
        dailyRate: parseInt(centsToDollars(car.dailyPriceInCents)),
        weeklyDiscount: calculateWeeklyDiscount(
          car.dailyPriceInCents,
          car.weeklyPriceInCents
        ),
        monthlyDiscount: calculateMonthlyDiscount(
          car.dailyPriceInCents,
          car.monthlyPriceInCents
        )
      }}
      setDisplayUnsavedProgressDialog={setDisplayUnsavedProgressDialog}
      setUnsavedProgressExistsState={setUnsavedProgressExistsState}
      unsavedProgressExists={unsavedProgressExists}
      carOverpriceSummary={carOverpriceSummary}
      isActive={isActive}
      carYear={car.year}
      carMake={car.make}
      carModel={car.model}
    />
  );
};

ListingTermsForm.propTypes = {
  carId: PropTypes.string
};
