export const AnalyticsEvents = {
  applications: {
    web: "Web"
  },
  category: {
    userInteraction: "User Interaction",
    deviceNetworking: "Device Networking"
  },
  action: {
    buttonClicked: "Button Clicked",
    webConversion: "Web Conversion",
    submission: "Submission",
    errorShown: "Error Shown",
    alertShown: "Alert Shown",
    requestFailed: "Request Failed",
    formSubmitted: "Form Submission",
    cardClicked: "Card Clicked"
  },
  event: {
    location: {
      permissionAccepted: "Permissions Accept",
      permissionDenied: "Permissions Denied",
      userLocation: "User Location"
    }
  },
  label: {
    driver: {
      argyle: {
        event: "Argyle Event",
        userCreated: "Argyle User Created",
        accountConnected: "Argyle Account Connected",
        accountError: "Argyle Account Error",
        accountUpdated: "Argyle Account Updated",
        accountRemoved: "Argyle Account Removed",
        payDistribution: "Argyle Pay Distrubution",
        error: "Argyle Error",
        close: "Close Argyle",
        cancel: "Cancelled Argyle",
        open: "Argyle Opened"
      },
      contactUs: "USER_CONTACT_US",
      howItWorks: "HOW_IT_WORKS",
      bgcSubmitted: "DRIVER_PROFILE_BGC_SUBMITTED",
      bgcSMSProfile: "BGC_SMS_PROFILE",
      extensionRequest: "Rental Extension Request",
      extensionError: "DRIVER_EXTENDED_RENTAL_ERROR",
      extensionSuccess: "Rental Extension Request Success",
      rentalExtended: "DRIVER_EXTENDED_RENTAL",
      bgcAboutYou: "DRIVER_BGC_FLOW_ABOUT_YOU",
      bgcAboutYouError: "DRIVER_BGC_FLOW_ABOUT_YOU_ERROR",
      bgcSubmission: "BGC Submission",
      bgcPaymentSubmitted: "DRIVER_BGC_PAYMENT_SUBMITTED",
      bgcPaymentError: "DRIVER_BGC_PAYMENT_SUBMITTED_ERROR",
      bgcSubmissionSuccess: "BGC Submission Success",
      bgcFlowSubmitted: "DRIVER_BGC_FLOW_SUBMITTED",
      bgcFlowSubmittedError: "DRIVER_BGC_FLOW_SUBMITTED_ERROR",
      driverCarSimilarCarShown: "Similar Car Selection",
      driverCarApplicationSubmitted: "DRIVER_CAR_APPLICATION_SUBMITTED",
      driverCarApplicationError: "DRIVER_CAR_APPLICATION_SUBMITTED_ERROR",
      bgcSMSOffer: "BGC_SMS_OFFER",
      startedMessage: "STARTED_MESSAGE",
      startedMessageError: "STARTED_MESSAGE_ERROR",
      offerDetailsBookNow: "OFFER_DETAILS_BOOK_NOW",
      enteredCustomLocation: "Entered Custom Location",
      enteredUserLocation: "Entered User Location",
      filtersApplied: "Filters Applied",
      applySortOptions: "Apply Sort Options",
      offerSelected: {
        legacyEventName: "Offer Selected",
        label: "Offer"
      },
      downloadAppClicked: {
        legacyEventName: "User Clicked Download App",
        label: "Clicked Download App"
      },
      selectedServices: "Selected Services"
    },
    owner: {
      NPSFormSubmitted: "NPS Form Submitted",
      NPSFormDismissed: "NPS Form Dismissed",
      dropoffConfirmed: "Dropoff Confirmed",
      dropoffSuccess: "Dropoff Confirmed Success",
      submittedACH: "User submitted ACH",
      ACHUpdateFailed: "ACH Update Failed",
      bookingApproved: "Booking Approved",
      bookingSuccess: "Booking Approved Success",
      bookingFailed: "Booking Approved Failed",
      driverPaymentError: "Driver Payment Error",
      ownerPayoutError: "Owner Payout Error",
      bulkUploadDocuments: "Bulk Upload Car Listing Documents",
      bulkUploadSuccess: "Bulk Upload Car Listed Success",
      bulkUploadFail: "Bulk Upload Car Listed Fail",
      ownerToggleFleet: "OWNER_TOGGLE_FLEET_APP",
      ownerACHSubmitError: "OWNER_ACH_SUBMIT_ERROR",
      rejectApplicationError: "REJECT_APPLICATION_ERROR",
      rejectApplication: "REJECT_APPLICATION",
      approveApplicationError: "APPROVE_APPLICATION_ERROR",
      approveApplication: "APPROVE_APPLICATION",
      confirmDropoffError: "CONFIRM_DROPOFF_ERROR",
      confirmDropoff: "CONFIRM_DROPOFF",
      damageReported: "DAMAGE_REPORTED",
      ownerRatedDriverError: "OWNER_RATED_DRIVER_ERROR",
      ownerRatedDriver: "OWNER_RATED_DRIVER",
      confirmPickupError: "CONFIRM_PICKUP_ERROR",
      confirmPickup: "CONFIRM_PICKUP",
      allowExtensions: "ALLOW_EXTENSIONS",
      disallowExtensions: "DISALLOW_EXTENSIONS",
      ownerEditCarError: "OWNER_EDIT_CAR_ERROR",
      ownerEditCar: "OWNER_EDIT_CAR",
      ownerListCar: "OWNER_LIST_CAR",
      ownerListCarError: "OWNER_LIST_CAR_ERROR",
      pickupConfirmed: "Pick Up Confirmed",
      pickupConfirmedSuccess: "Pick Up Confirmed Success",
      carListed: "Car Listed",
      carListedSuccess: "Car Listed Success",
      carListedHC4B: "Car Listed HC4B",
      carListedHC4BSuccess: "Car Listed Success HC4B",
      ownerICCProtectionComplete: "OWNER_ICC_PROTECTION_COMPLETE",
      ownerICCTermsComplete: "OWNER_ICC_TERMS_COMPLETE",
      exportTransactions: "Export Transactions",
      exportTransactionsError: "Export Transactions Error",
      viewTransactions: "View Transactions",
      viewTransactionsError: "View Transactions Error",
      viewPayouts: "View Payouts",
      viewPayoutsError: "View Payouts Error",
      viewPayoutDetails: "View Payout Details",
      viewPayoutDetailsError: "View Payout Details Error",
      currentListingsAnswered: "Current Listings Answered",
      ownerOnboardingQuestionsSubmissionFailure:
        "Owner Onboarding Questions Submission Failed",
      currentListingsWentBack: "Current Listings Went Back",
      listingPhotoSlideShowOpened: "Listing Photo Slide Show Opened",
      listingPhotoSlideShowClosed: "Listing Photo Slide Show Closed",
      listings: "Listings",
      carSnoozed: "Car snoozed",
      carDeleted: "Car Deleted",
      vinInputError: "Vin Input Error",
      vinValidationSuccess: "Vin Validation Success",
      startedCarListing: "Started Car Listing",
      saveListingError: "Save Listing Error",
      restoreListingError: "Restore Listing Error",
      restoreListingSuccess: "Restore Listing Success",
      carOverpricedAlert: "Car Overpriced Alert"
    },
    auth: {
      driverSignedIn: "DRIVER_SIGNED_IN_WEB",
      driverRegistered: "DRIVER_REGISTERED_WEB",
      ownerSignedIn: "OWNER_SIGNED_IN_WEB",
      ownerRegistered: "OWNER_REGISTERED_WEB",
      loggedOut: "USER_LOG_OUT",
      socialLogin: "Social Login",
      tosAccepted: "TOS Accepted",
      tosUpdated: "TOS Updated",
      shortCodeEnabled: "Short Code Enabled",
      uberLeadRegistered: "Uber Lead Registered",
      locationPermissionAccepted: "Location",
      locationPermissionDenied: "Location",
      locationRequestFailed: "Location",
      locationRetrieved: "Location",
      userRegisteredSuccessfully: {
        legacyEventName: "User Registration",
        label: "Registration"
      },
      userRegistrationFailed: {
        legacyEventName: "User Registration Error",
        label: "Request Failed"
      },
      signUpSelected: {
        legacyEventName: "User Selected Register",
        label: "Register"
      },
      signUpWithGoogleSelected: {
        legacyEventName: "User Selected Google Register",
        label: "Google Register"
      },
      signUpWithAppleSelected: {
        legacyEventName: "User Selected Apple Register",
        label: "Apple Register"
      },
      signUpSubmitted: {
        legacyEventName: "Request Account Creation",
        label: "Request Account Creation"
      },
      logInSelected: {
        legacyEventName: "User Selected Login",
        label: "Login"
      },
      logInWithGoogleSelected: {
        legacyEventName: "User Selected Google Login",
        label: "Google"
      },
      logInWithAppleSelected: {
        legacyEventName: "User Selected Apple Login",
        label: "Apple"
      },
      logInWithFacebookSelected: {
        legacyEventName: "User Selected FB Login",
        label: "Facebook"
      },
      loginRequested: {
        legacyEventName: "Request Login",
        label: "Request Login"
      },
      loginFailed: {
        legacyEventName: "User Login Error",
        label: "Login Request"
      },
      userLoggedIn: {
        legacyEventName: "User Login",
        label: "Login"
      },
      passwordResetRequested: {
        legacyEventName: "User Requested Password Reset",
        label: "Request Password Reset"
      },
      viewTOSClicked: "View TOS",
      viewPrivacyPolicyClicked: "View Privacy Policy"
    },
    lead: {
      trackReferrerError: "Lead Track Referrer Error",
      trackReferrerSuccess: "Lead Track Referrer Success"
    },
    location: "Location",
    tutorial: "Tutorial"
  },
  flow: {
    registration: "Registration",
    onboarding: "Onboarding",
    login: "Login",
    auth: "Auth",
    requestBooking: "Request Booking"
  }
};
