import { gql } from "@apollo/client";

import { RentalFragments } from "./RentalFragments";
import { RentalPeriodFragments } from "../RentalPeriod/RentalPeriodFragments";
import { CarFragments } from "../Car/CarFragments";
import { DriverFragments } from "../Driver/DriverFragments";
import { GeneralFragments } from "../General/GeneralFragments";
import { FileFragments } from "../File/FileFragments";
import { UserFragments } from "Queries/User/UserFragments";

export const RENTALS_QUERY = gql`
  query RentalsQuery(
    $before: String
    $after: String
    $searchText: String
    $filter: RentalWhereInput
    $first: Int
    $skip: Int
    $orderBy: RentalOrderByInput
  ) {
    viewer {
      id
      me {
        id
        owner {
          id
          rentalsConnection(
            before: $before
            after: $after
            searchText: $searchText
            filter: $filter
            first: $first
            orderBy: $orderBy
            skip: $skip
          ) {
            pageInfo {
              ...CursorInfo
            }
            edges {
              node {
                ...RentalInfo
                overview {
                  ...RentalOverview
                }
                currentRentalPeriod {
                  ...RentalPeriodDates
                }
                rentalPeriods(first: 1, orderBy: startDate_ASC) {
                  ...RentalPeriodDates
                }
                car {
                  ...CarInfo
                  photos(where: { isPrimary: true }) {
                    ...CarPhoto
                  }
                }
                driver {
                  ...DriverRentalTable
                }
              }
            }
          }
        }
      }
    }
  }
  ${RentalFragments.rentalInfo}
  ${RentalFragments.rentalOverview}
  ${CarFragments.carInfo}
  ${CarFragments.carPhoto}
  ${GeneralFragments.cursorInfo}
  ${DriverFragments.driverRentalTable}
  ${RentalPeriodFragments.rentalPeriodDates}
`;

export const RENTAL_REQUEST_QUERY = gql`
  query RentalRequestQuery($id: ID) {
    rental(where: { id: $id }) {
      ...RentalRequest
    }
  }
  ${RentalFragments.rentalRequest}
`;

export const FIRST_RENTAL_PERIOD = gql`
  query FirstRentalPeriod($id: ID) {
    rental(where: { id: $id }) {
      id
      rentalPeriods(first: 1, orderBy: startDate_ASC) {
        ...RentalPeriodDates
      }
    }
  }
  ${RentalPeriodFragments.rentalPeriodDates}
`;

export const RENTAL_CONTRACTS = gql`
  query RentalContracts($id: ID) {
    rental(where: { id: $id }) {
      id
      rentalPeriods(orderBy: startDate_DESC) {
        ...RentalPeriodDates
        rentalContract {
          ...File
        }
      }
    }
  }
  ${FileFragments.file}
  ${RentalPeriodFragments.rentalPeriodDates}
`;

export const RENTAL_PREPHOTOS = gql`
  query RentalPrePhotos($id: ID) {
    rental(where: { id: $id }) {
      id
      insurances {
        createdAt
        pre {
          photos {
            file {
              ...File
            }
          }
        }
      }
      rentalLookup {
        preRentalByRentalLookupId {
          preRentalPhotosByPreRentalId {
            nodes {
              file {
                ...File
              }
            }
          }
        }
      }
    }
  }
  ${FileFragments.file}
`;

export const RENTAL_DRIVER_PHOTOS = gql`
  query RentalDriverPhotos($id: ID) {
    rental(where: { id: $id }) {
      id
      driver {
        id
        licensePhoto {
          ...File
        }
        user {
          id
          profilePhoto {
            ...File
          }
        }
      }
    }
  }
  ${FileFragments.file}
`;

export const RENTAL_STATUS_QUERY = gql`
  query RentalStatusQuery($id: ID) {
    rental(where: { id: $id }) {
      ...RentalStatus
    }
  }
  ${RentalFragments.rentalStatus}
`;

export const RENTAL_OVERVIEW_QUERY = gql`
  query RentalOverviewQuery($id: ID) {
    rental(where: { id: $id }) {
      id
      driver {
        id
      }
      overview {
        ...RentalOverview
      }
    }
  }
  ${RentalFragments.rentalOverview}
`;

export const RENTAL_AGGREGATE_QUERY = gql`
  query RentalAggregateQuery($filter: RentalWhereInput) {
    viewer {
      id
      me {
        id
        owner {
          id
          rentalsConnection(filter: $filter) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;

export const RENTALS_FOR_CLAIMS = gql`
  query RentalsForClaims(
    $droppedOffAt_lt: DateTime!
    $droppedOffAt_gt: DateTime!
  ) {
    viewer {
      id
      me {
        id
        owner {
          id
          rentals(
            filter: {
              status_in: [COMPLETED]
              droppedOffAt_lt: $droppedOffAt_lt
              droppedOffAt_gt: $droppedOffAt_gt
            }
          ) {
            id
            rentalPeriods(orderBy: startDate_ASC, first: 1) {
              ...RentalPeriod
            }
            currentRentalPeriod {
              ...RentalPeriod
            }
            car {
              ...CarRentalRequest
            }
            driver {
              id
              user {
                ...UserFirstAndLastName
              }
            }
          }
        }
      }
    }
  }
  ${RentalPeriodFragments.rentalPeriod}
  ${CarFragments.carRentalRequest}
  ${UserFragments.userFirstAndLastName}
`;

export const MUTUAL_RENTALS = gql`
  query MutualRentals($driverId: ID) {
    viewer {
      id
      me {
        id
        owner {
          id
          rentals(filter: { driverId: $driverId }, last: 1) {
            id
            status
            requestedPickupAt
            overview {
              id
              startDate
              endDate
            }
            car {
              id
              make
              model
              year
              vin
              photos(where: { isPrimary: true }) {
                file {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MUTUAL_RENTALS_MULTIPLE = gql`
  query MutualRentals($driverIds: [ID!]) {
    viewer {
      id
      me {
        id
        owner {
          id
          rentals(filter: { driverId_in: $driverIds }) {
            id
            driverId
            status
            requestedPickupAt
            overview {
              id
              startDate
              endDate
            }
            car {
              id
              make
              model
              year
              vin
            }
          }
        }
      }
    }
  }
`;

export const EMPLOYMENT_HISTORY = gql`
  query EmploymentHistory($driverId: String) {
    fetchArgyleEmployementHistory(driverId: $driverId) {
      id
      account
      employer
      employmentStatus
      employmentType
      originalHireDate
      terminationDate
      terminationReason
      gigsCount
      mostRecentGigDate
    }
  }
`;

export const DRIVER_INFO = gql`
  query DriverInfo($driverId: ID!) {
    viewer {
      id
      me {
        id
        owner {
          id
          rentals(
            filter: { driverId: $driverId }
            first: 1
            orderBy: createdAt_DESC
          ) {
            id
            createdAt
            status
            driver {
              id
              aboutMe
              backgroundCheck {
                verificationStatus
              }
              user {
                id
                createdAt
                firstName
                lastName
                phone
                profilePhoto {
                  id
                  url
                }
                location {
                  city
                  state
                }
              }
              rentals(filter: { status: COMPLETED }) {
                aggregate {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MUTUAL_COMPLETED_RENTALS = gql`
  query mutualCompletedRentals($driverId: ID!) {
    viewer {
      id
      me {
        id
        owner {
          id
          rentals(filter: { driverId: $driverId, status: COMPLETED }) {
            id
            status
            droppedOffAt
            rentalPeriods(orderBy: startDate_ASC, first: 1) {
              id
              createdAt
              startDate
            }
            car {
              id
              vin
              year
              make
              model
              photos(where: { isPrimary: true }) {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const RENTAL_REIMBURSEMENT_QUERY = gql`
  query rentalReimbursementQuery($id: ID) {
    rental(where: { id: $id }) {
      ...RentalRequest
      overview {
        ...RentalOverview
      }
    }
  }
  ${RentalFragments.rentalRequest}
  ${RentalFragments.rentalOverview}
`;
