import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { InfoDrawer } from "Components/Drawers/InfoDrawer";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { DriverProfileContext } from "Components/Utils/DriverProfileProvider";
import { DRIVER_INFO, EMPLOYMENT_HISTORY } from "Queries/Rentals/RentalQueries";
import { MUTUAL_COMPLETED_RENTALS } from "Queries/Rentals/RentalQueries";
import { useQuery } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { connect } from "react-redux";
import { asyncStartChannel } from "Redux/SendBird/ActionCreators";
import { checkPermission } from "Utils/RooftopUtils";
import { InboxDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { RouteEnum } from "Enums/RouteEnum";
import { useHistory } from "react-router-dom";
import { formatPhone } from "Utils/Helpers";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import { LetterAvatar } from "Components/Avatar/LetterAvatar";
import Link from "@mui/material/Link";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import PhoneIcon from "@mui/icons-material/Phone";
import ChatIcon from "@mui/icons-material/Chat";
import IconButton from "@mui/material/IconButton";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import classNames from "classnames";
import { DriverPhoneVerificationBadge } from "Components/User/driver-phone-verification-badge/DriverPhoneVerificationBadge";

const useStyles = makeStyles(theme => ({
  drawerContainer: props => ({
    width: props.matches ? "100vw" : theme.spacing(65.625)
  }),
  appBar: props => ({
    width: props.matches ? "100vw" : theme.spacing(65.625),
    marginBottom: theme.spacing(2)
  }),
  profileHeader: {
    padding: theme.spacing(1.75)
  },
  image: {
    width: "84px",
    height: "84px",
    marginLeft: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1)
  },
  sectionInfo: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "auto"
  },
  helpIcon: {
    fontSize: "1.25em",
    marginRight: theme.spacing(0.5)
  },
  loadingComponent: {
    margin: theme.spacing(0.75)
  },
  imageLoading: {
    margin: "6px 0 6px 20px"
  },
  profileCard: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    margin: "0 14px",
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  buttonContainer: {
    justifyContent: "center"
  },
  callButton: props => ({
    borderRight: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRadius: "0",
    width: props.displayPhone ? "50%" : "100%",
    padding: "20px 0"
  }),
  messageButton: props => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRadius: "0",
    width: props.displayPhone ? "50%" : "100%",
    padding: "20px 0"
  }),
  infoItem: {
    marginTop: "0.15em"
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  rentalContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  transportationIcon: {
    width: "50px",
    height: "45px",
    color: "#BEBEBE"
  },
  historyIcon: {
    width: "50px",
    height: "45px",
    color: "#BEBEBE"
  },
  iconContainer: {
    padding: "13.5px 17.5px",
    marginLeft: theme.spacing(2.5)
  },
  rentalInfo: {
    justifyContent: "center",
    marginLeft: theme.spacing(2),
    width: "55%"
  },
  fontStyle: {
    color: "#000000",
    fontWeight: "400"
  },
  sectionTitle: {
    margin: "0 30px",
    marginBottom: theme.spacing(2)
  },
  textBody: {
    margin: "0 16px",
    marginBottom: theme.spacing(2)
  },
  overline: {
    lineHeight: "1.5",
    color: "#000000DE",
    marginTop: "5px"
  },
  hover: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  viewDriverChevron: {
    height: "auto",
    width: "22px"
  },
  inlineDriverContainer: {
    display: "flex",
    flex: 1,
    width: "100%",
    justifyContent: "space-between"
  },
  driverProfileCardContainer: {
    display: "flex",
    flex: 1,
    marginBottom: "16px"
  },
  profileImage: {
    marginRight: "16px"
  },
  activeStatus: {
    color: "#00b050"
  },
  inActiveStatus: {
    color: "#EA4335"
  }
}));

const DriverProfileHeader = ({ onClose, classes }) => {
  return (
    <Grid className={classes.appBar}>
      <DrawerAppBar
        title="Driver Profile"
        border
        icon={
          <IconButton onClick={onClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        }
      />
    </Grid>
  );
};

export const DriverProfileCard = ({
  driver,
  displayPhone,
  inlineDriverRedirect = false
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const hasPhone = driver?.user?.phone;
  const isVerified = driver?.backgroundCheck?.verificationStatus === "VERIFIED";

  const { onDrawerOpen } = useContext(DriverProfileContext);

  const classes = useStyles({ matches, displayPhone });

  const openDriverProfile = () => onDrawerOpen(driver?.id, driver?.user?.id);

  const phoneNumber = driver?.user?.phone;
  const userId = driver?.user?.id;

  return (
    <div className={classes.driverProfileCardContainer}>
      <Avatar
        src={driver?.user?.profilePhoto?.url}
        alt="Driver Profile"
        className={classNames(classes.image, classes.profileImage)}
        variant="square"
      >
        <LetterAvatar firstName={driver?.user?.firstName} />
      </Avatar>
      <Grid container direction="column">
        {inlineDriverRedirect ? (
          <div className={classes.inlineDriverContainer}>
            <Typography variant="h6" className={classes.fontStyle}>
              {`${driver?.user?.firstName} ${driver?.user?.lastName?.slice(
                0,
                1
              )}.`}
            </Typography>
            <Button
              color="primary"
              onClick={openDriverProfile}
              className={classes.hover}
            >
              <Typography component="p" variant="body">
                VIEW DRIVER
              </Typography>
              <ChevronRightIcon className={classes.viewDriverChevron} />
            </Button>
          </div>
        ) : (
          <Typography variant="h6" className={classes.fontStyle}>
            {`${driver?.user?.firstName} ${driver?.user?.lastName?.slice(
              0,
              1
            )}.`}
          </Typography>
        )}
        <Typography variant="body2">
          {driver?.user?.location?.city},{" "}
          {driver?.user?.location?.state?.toUpperCase()}
        </Typography>
        {hasPhone && displayPhone && (
          <Link
            className={classes.infoItem}
            href={hasPhone ? `tel:${formatPhone(driver?.user?.phone)}` : null}
            underline="hover"
          >
            {formatPhone(driver?.user?.phone)}
          </Link>
        )}
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.infoItem}
        >
          <Tooltip title="Verified to drive means this driver has passed HyreCar’s screening and background check.">
            <BeenhereIcon color="primary" className={classes.helpIcon} />
          </Tooltip>
          <Typography variant="body2">
            {isVerified ? "Verified to drive" : "Not verified to drive"}
          </Typography>
        </Grid>
        <DriverPhoneVerificationBadge
          phoneNumber={phoneNumber}
          userId={userId}
        />
        <Typography variant="body2" className={classes.infoItem}>
          Member since {moment(driver?.user?.createdAt).format("MMM YYYY")}
        </Typography>
      </Grid>
    </div>
  );
};

const DriverRentalHistory = ({
  classes,
  driver,
  driverCompletedRentalCount,
  mutualCompletedRentalCount
}) => {
  const { currentRooftopToken } = useContext(ClientFactoryContext);
  const otherCompletedRentalCount =
    driverCompletedRentalCount - mutualCompletedRentalCount;
  return (
    <Grid container direction="row" className={classes.rentalContainer}>
      <div className={classes.iconContainer}>
        <EmojiTransportationIcon className={classes.transportationIcon} />
      </div>
      <Grid container direction="column" className={classes.rentalInfo}>
        {driverCompletedRentalCount ? (
          <>
            <Typography variant="subtitle1" className={classes.fontStyle}>
              Rental History
            </Typography>
            <Typography variant="body2">
              {`${mutualCompletedRentalCount} ${
                mutualCompletedRentalCount === 1 ? `rental` : `rentals`
              } with ${jwtDecode(currentRooftopToken)?.name}`}
            </Typography>
            <Typography variant="body2">{`${otherCompletedRentalCount} ${
              otherCompletedRentalCount === 1 ? `rental` : `rentals`
            } with other owners`}</Typography>
          </>
        ) : (
          <>
            <Typography
              variant="body1"
              className={`${classes.infoItem} ${classes.fontStyle}`}
            >{`${driver?.user?.firstName} ${driver?.user?.lastName?.slice(
              0,
              1
            )}. does not have any prior HyreCar rentals.`}</Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const EmploymentHistory = ({ classes, items }) => {
  if (!items.length) {
    return <></>;
  }

  return (
    <Grid container direction="row" className={classes.rentalContainer}>
      <div className={classes.iconContainer}>
        <ManageHistoryIcon className={classes.historyIcon} />
      </div>
      <Grid container direction="column" className={classes.rentalInfo}>
        <>
          <Typography variant="subtitle1" className={classes.fontStyle}>
            Employment History
          </Typography>

          {items.map(history => (
            <Grid
              container
              direction="column"
              className={classes.rentalContainer}
              key={history.id}
            >
              <Typography variant="subtitle1" className={classes.fontStyle}>
                {history.employer}
              </Typography>

              <Typography variant="body2">
                Trip count: {history.gigsCount}
              </Typography>

              <Typography variant="body2">
                Most recent gig:{" "}
                {history.mostRecentGigDate
                  ? moment(history.mostRecentGigDate).format("MMM D, YYYY")
                  : "N/A"}
              </Typography>

              <Typography variant="body2">
                Driving since:{" "}
                {moment(history.originalHireDate).format("MMM D, YYYY")}
              </Typography>
            </Grid>
          ))}
        </>
      </Grid>
    </Grid>
  );
};

export const CallDriverButton = ({ classes, driver }) => {
  const hasPhone = driver?.user?.phone;
  return (
    <Button
      href={hasPhone ? `tel:${formatPhone(driver?.user?.phone)}` : null}
      color="primary"
      className={classes.callButton}
    >
      <Grid container className={classes.buttonContainer}>
        <PhoneIcon className={classes.buttonIcon} />
        <Typography color="primary" variant="button">
          CALL
        </Typography>
      </Grid>
    </Button>
  );
};

export const MessageDriverButton = ({ classes, onClick }) => {
  return (
    <Button onClick={onClick} color="primary" className={classes.messageButton}>
      <Grid container className={classes.buttonContainer}>
        <ChatIcon className={classes.buttonIcon} />
        <Typography color="primary" variant="button">
          MESSAGE DRIVER
        </Typography>
      </Grid>
    </Button>
  );
};

const DriverAboutMe = ({ classes, driver }) => {
  return (
    <Grid container>
      <Typography variant="h6" className={classes.sectionTitle}>
        {`About ${driver?.user?.firstName} ${driver?.user?.lastName?.slice(
          0,
          1
        )}.`}
      </Typography>
      <Grid className={classes.profileCard}>
        <Typography variant="body2" className={classes.textBody}>
          {driver?.aboutMe}
        </Typography>
      </Grid>
    </Grid>
  );
};

const DriverHistoryDetails = ({ classes, rentals, history, onClose }) => {
  const { currentRooftopToken } = useContext(ClientFactoryContext);
  const RentalCard = rental => {
    const rentalData = rental?.rental;
    const startDate = rentalData?.rentalPeriods[0]?.startDate;
    const endDate = rentalData?.droppedOffAt;
    const rentalDays = moment(endDate).diff(startDate, "days");
    const handleClick = () => {
      history.replace(`${RouteEnum.rentalsPast}/${rentalData?.id}`);
      onClose();
    };
    return (
      <Grid
        container
        direction="row"
        className={`${classes.profileCard} ${classes.hover}`}
        onClick={handleClick}
      >
        <Grid>
          <Avatar
            src={rentalData?.car?.photos[0]?.file?.url}
            alt="Car"
            variant="square"
            className={classes.image}
          />
        </Grid>
        <Grid container direction="column" className={classes.sectionInfo}>
          <Typography
            variant="overline"
            className={classes.overline}
          >{`Completed ${moment(rentalData?.rentalPeriods[0]?.startDate).format(
            "MM/DD/YY"
          )} - ${moment(rentalData?.droppedOffAt).format(
            "MM/DD/YY"
          )}`}</Typography>
          <Typography
            variant="subtitle1"
            className={classes.fontStyle}
          >{`${rentalData?.car?.year} ${rentalData?.car?.make} ${rentalData?.car?.model}`}</Typography>
          <Typography variant="body2">{rentalData?.car?.vin}</Typography>
          <Typography variant="body2">{`${rentalDays} Day Rental`}</Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container>
      <Typography variant="h6" className={classes.sectionTitle}>
        {`Rental History with ${jwtDecode(currentRooftopToken)?.name}`}
      </Typography>
      {rentals?.map(rental => {
        return <RentalCard key={rental.id} rental={rental} />;
      })}
    </Grid>
  );
};

export const DriverProfile = connect(null, {
  asyncStartChannel
})(({ driverId, driverUserId, asyncStartChannel }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const { onDrawerClose } = useContext(DriverProfileContext);
  const { currentRooftopClient, scopes } = useContext(ClientFactoryContext);

  const { data: driverInfo, loading } = useQuery(DRIVER_INFO, {
    variables: { driverId: driverId },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });
  const { data: completedRentals, loading: completedRentalsLoading } = useQuery(
    MUTUAL_COMPLETED_RENTALS,
    {
      variables: { driverId: driverId },
      client: currentRooftopClient,
      fetchPolicy: "no-cache"
    }
  );
  const {
    data: employmentHistory,
    loading: employmentHistoryLoading
  } = useQuery(EMPLOYMENT_HISTORY, {
    variables: { driverId: driverUserId },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  const employmentHistoryItems =
    employmentHistory?.fetchArgyleEmployementHistory || [];
  const driver = driverInfo?.viewer?.me?.owner?.rentals[0]?.driver;
  const ownerDriverRentals = completedRentals?.viewer?.me?.owner?.rentals;
  const rentalStatus = driverInfo?.viewer?.me?.owner?.rentals[0]?.status;
  const displayPhone = [
    "PENDING_INSURANCE",
    "PENDING_PICKUP",
    "ACTIVE",
    "LATE"
  ].includes(rentalStatus);
  const driverCompletedRentalCount =
    driverInfo?.viewer?.me?.owner?.rentals[0]?.driver?.rentals?.aggregate
      ?.count;
  const mutualCompletedRentalCount =
    completedRentals?.viewer?.me?.owner?.rentals?.length;
  const handleStartMessage = async () => {
    await asyncStartChannel(driverId);
    history.push(
      checkPermission(InboxDomainPermissionPaths.viewMultiUserInbox, scopes)
        ? RouteEnum.inboxMine
        : RouteEnum.inbox
    );
    onDrawerClose();
  };

  const classes = useStyles({ matches, displayPhone });

  const renderLoadingComponent = () => {
    return (
      <Grid container direction="row" alignItems="center">
        <Skeleton
          variant="circular"
          animation="wave"
          width={90}
          height={90}
          className={classes.imageLoading}
        />
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          className={classes.sectionInfo}
        >
          <Skeleton
            variant="text"
            animation="wave"
            width={matches ? 210 : 280}
            height={15}
            className={classes.loadingComponent}
          />
          <Skeleton
            variant="text"
            animation="wave"
            width={matches ? 210 : 280}
            height={15}
            className={classes.loadingComponent}
          />
          <Skeleton
            variant="text"
            animation="wave"
            width={matches ? 210 : 280}
            height={15}
            className={classes.loadingComponent}
          />
          <Skeleton
            variant="text"
            animation="wave"
            width={matches ? 210 : 280}
            height={15}
            className={classes.loadingComponent}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <InfoDrawer open={true} onClose={onDrawerClose}>
      <Grid container className={classes.drawerContainer}>
        <DriverProfileHeader onClose={onDrawerClose} classes={classes} />
        <Grid container direction="row" alignItems="center">
          {loading || completedRentalsLoading || employmentHistoryLoading ? (
            renderLoadingComponent()
          ) : (
            <>
              <div className={classes.profileCard}>
                <Grid container direction="row" alignItems="flex-start">
                  <DriverProfileCard
                    classes={classes}
                    driver={driver}
                    displayPhone={displayPhone}
                  />
                  <DriverRentalHistory
                    classes={classes}
                    driver={driver}
                    driverCompletedRentalCount={driverCompletedRentalCount}
                    mutualCompletedRentalCount={mutualCompletedRentalCount}
                  />
                  <EmploymentHistory
                    classes={classes}
                    items={employmentHistoryItems}
                  />
                  <Grid container>
                    {displayPhone && (
                      <CallDriverButton classes={classes} driver={driver} />
                    )}
                    <MessageDriverButton
                      classes={classes}
                      onClick={handleStartMessage}
                    />
                  </Grid>
                </Grid>
              </div>
              {driver?.aboutMe && (
                <DriverAboutMe classes={classes} driver={driver} />
              )}
              {ownerDriverRentals?.length > 0 && (
                <DriverHistoryDetails
                  classes={classes}
                  rentals={ownerDriverRentals}
                  history={history}
                  onClose={onDrawerClose}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </InfoDrawer>
  );
});
