import React from "react";
import connectStats from "react-instantsearch/src/connectors/connectStats";
import { compose } from "recompose";
import makeStyles from "@mui/styles/makeStyles";
import TablePagination from "@mui/material/TablePagination";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  tablePaginationToolbar: {
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(2)
    }
  },
  tablePaginationSelect: {
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(1)
    }
  }
}));

export const MarketplacePagination = compose(connectStats)(
  ({ nbHits, page, handleChangePage, rows, handleChangeRowsPerPage }) => {
    const classes = useStyles();

    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={nbHits}
        rowsPerPage={rows}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          toolbar: classes.tablePaginationToolbar,
          selectRoot: classes.tablePaginationSelect
        }}
      />
    );
  }
);

MarketplacePagination.propTypes = {
  rows: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  handleChangePage: PropTypes.func
};
