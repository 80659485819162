import React, { useState, useEffect, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { compose } from "recompose";
import { reduxForm, Field } from "redux-form";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CarSwitchIcon from "@mui/icons-material/SyncAlt";
import CreateIcon from "@mui/icons-material/Create";
import CollectionsIcon from "@mui/icons-material/Collections";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import get from "lodash/get";
import moment from "moment";

import { RouteEnum } from "Enums/RouteEnum";
import { OWNER_DEFAULT_PROTECTION_PLAN } from "Queries/Owner/OwnerQueries";
import {
  RENTAL_REQUEST_QUERY,
  RENTAL_AGGREGATE_QUERY,
  RENTAL_OVERVIEW_QUERY
} from "Queries/Rentals/RentalQueries";
import {
  REJECT_BOOKING,
  TOGGLE_RENTAL_EXTENSION
} from "Mutations/Rental/RentalMutations";
import { RentalStatusEnum } from "Enums/StateEnums";
import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";
import { RentalDrawerHead } from "Components/Drawers/RentalDrawer/RentalDrawerHead";
import { RentalDrawerActions } from "Components/Drawers/RentalDrawer/RentalDrawerActions";
import { OptionCard } from "Components/Cards/OptionCard";
import { DrawerInfoCard } from "Components/Drawers/DrawerInfoCard";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerManageRow } from "Components/Drawers/DrawerManageRow";
import { BasicTable } from "Components/Tables/BasicTable";
import { FormCheckbox } from "Components/Inputs/FormCheckbox";
import {
  calculateEarningsSummary,
  centsToDollars,
  calculateTimePastCompleted
} from "Utils/Calculations";
import { formatPhone } from "Utils/Helpers";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { ApproveBookingFormController } from "Components/Forms/FormControllers/ApproveBookingFormController";
import { AchFormController } from "Components/Forms/FormControllers/AchFormController";
import { AchDrawerForm } from "Components/Drawers/RentalDrawer/AchDrawerForm";
import { ListingDrawerInner } from "Components/Drawers/ListingDrawer/ListingDrawer";
import { RentalsDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { renderByPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { DriverProfileContext } from "Components/Utils/DriverProfileProvider";
import { snakeToProperCase } from "Utils/Helpers";
import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import { TosContext } from "Components/Utils/TosProvider";
import { appendCommasToUSDollarsString } from "Utils/textFormatting";
// We are leaving this code in, pending a bug fix - as of 2/21
// import { OwnerAddressDrawerForm } from "Components/Drawers/RentalDrawer/OwnerAddressDrawerForm";
// import { UpdateRooftopAddressFormController } from "Components/Forms/FormControllers/UpdateRooftopAddressFormController";
// import { ROOFTOP_INFO } from "Queries/Rooftop/RooftopQueries";

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(3, 0)
  },
  checkbox: {
    padding: theme.spacing(0, 2)
  }
}));

const EarningsTotalSection = ({ rentalOverviewData, scopes, status }) => {
  const ownerEarningsInCents = get(
    rentalOverviewData,
    "rental.overview.ownerEarningsInCents",
    "$0.00"
  );
  const netEarningsString = `$${centsToDollars(ownerEarningsInCents)}`;
  const netEarningsWithCommas = appendCommasToUSDollarsString(
    netEarningsString
  );

  const Component = (
    <DrawerManageRow
      cursor={"default"}
      icon={<AttachMoneyOutlinedIcon color="primary" />}
      actionText={`Rental Earnings: ${netEarningsWithCommas}`}
    />
  );

  if (status === RentalStatusEnum.completed) {
    return renderByPermissions(
      Component,
      [RentalsDomainPermissionPaths.pastViewEarnings],
      scopes
    );
  } else {
    return Component;
  }
};

export const RentalDrawerSummaryForm = compose(
  reduxForm({
    form: "RENTAL_REQUEST"
  })
)(
  ({
    handleSubmit,
    handleClose,
    sendToRentalContracts,
    sendToRentalImages,
    sendToCarSwitch,
    sendToCarSwitchLateRentalUnavailable,
    sendToCheckOut,
    submitting,
    requiresAch,
    sendToChangeActiveDailyPrice
  }) => {
    const classes = useStyles();
    const history = useHistory();
    const [checked, setChecked] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const [showListingDrawer, setShowListingDrawer] = useState(false);
    const { scopes, currentRooftopClient } = useContext(ClientFactoryContext);
    const [rejectBooking] = useMutation(REJECT_BOOKING, {
      client: currentRooftopClient
    });
    const [toggleExtensions] = useMutation(TOGGLE_RENTAL_EXTENSION, {
      client: currentRooftopClient
    });
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const { data, loading } = useQuery(RENTAL_REQUEST_QUERY, {
      variables: {
        id
      },
      fetchPolicy: "network-only",
      client: currentRooftopClient,
      context: {
        important: true
      }
    });
    const {
      data: rentalOverviewData,
      loading: rentalOverviewLoading
    } = useQuery(RENTAL_OVERVIEW_QUERY, {
      variables: {
        id
      },
      client: currentRooftopClient,
      context: {
        important: true
      }
    });
    useQuery(OWNER_DEFAULT_PROTECTION_PLAN, {
      client: currentRooftopClient,
      context: {
        important: true
      }
    });
    const status = get(data, "rental.status", RentalStatusEnum.cancelled);
    const carProtectionPlan = get(
      data,
      "rental.rentalPeriods[0].protectionPlan"
    );

    let startDate = data?.rental?.requestedPickupAt;
    const numDays = data?.rental?.currentRentalPeriod?.numDays;
    let endDate = moment(startDate).add(numDays, "days");

    const activeRental =
      data?.rental?.status === RentalStatusEnum.active ||
      data?.rental?.status === RentalStatusEnum.late;
    const completedRental = data?.rental?.status === RentalStatusEnum.completed;
    if (activeRental) {
      startDate = data?.rental?.currentRentalPeriod?.startDate;
      endDate = data?.rental?.currentRentalPeriod?.endDate;
    }
    if (completedRental) {
      startDate = data?.rental?.currentRentalPeriod?.startDate;
      endDate = data?.rental?.droppedOffAt;
    }

    const checkInFormat =
      activeRental || completedRental
        ? "ddd, MMM D [at] hh:mm A"
        : "ddd, MMM D";

    const dailyPriceInCents = get(data, "rental.car.dailyPriceInCents");
    const droppedOffAt = get(data, "rental.droppedOffAt");
    const {
      grossEarningsInCents,
      netEarningsInCents,
      totalProtectionPlanFeeInCents
    } = calculateEarningsSummary(
      numDays,
      dailyPriceInCents,
      carProtectionPlan || "None"
    );

    useEffect(() => {
      if (data && data.rental) setChecked(!data.rental.disallowExtensions);
    }, [data]);

    const handleShowListingDrawer = () => setShowListingDrawer(true);
    const handleCloseListingDrawer = () => setShowListingDrawer(false);

    const { onDrawerOpen } = useContext(DriverProfileContext);

    const driverId = get(data, "rental.driver.id", "");
    const driverUserId = get(data, "rental.driver.user.id", "");
    const openDriverProfile = () => onDrawerOpen(driverId, driverUserId);

    const handleRejectBooking = async () => {
      try {
        setRejecting(true);
        await rejectBooking({
          variables: {
            rentalId: id
          },
          refetchQueries: [
            "RentalsQuery",
            {
              query: RENTAL_AGGREGATE_QUERY,
              variables: {
                filter: {
                  status: RentalStatusEnum.applied
                }
              }
            }
          ]
        });
        enqueueSnackbar("Rejected Booking.", {
          variant: "success"
        });
      } catch (e) {
        console.error(e);
        enqueueSnackbar("Sorry, something went wrong. Please try again.", {
          variant: "error"
        });
      } finally {
        setRejecting(false);
      }
    };

    const handleToggleExtensions = async e => {
      try {
        setChecked(e.target.checked);
        await toggleExtensions({
          variables: {
            rentalId: id
          }
        });
      } catch (e) {
        console.error(e);
        enqueueSnackbar("Sorry, something went wrong. Please try again.", {
          variant: "error"
        });
      }
    };

    return loading || rentalOverviewLoading ? (
      <SkeletonDrawer />
    ) : (
      <>
        {showListingDrawer ? (
          <ListingDrawerInner
            handleClose={handleCloseListingDrawer}
            carId={get(data, "rental.car.id")}
            useBackButton
          />
        ) : (
          <>
            <RentalDrawerHead onClose={handleClose} />
            <RentalDrawerActions />
            <DrawerPaddingContainer>
              <DrawerInfoCard
                overline="Driver"
                title={`${get(data, "rental.driver.user.firstName")} ${
                  get(data, "rental.driver.user.lastName", "")[0]
                }.`}
                subtitle={
                  ![
                    RentalStatusEnum.applied,
                    RentalStatusEnum.applicationAutoRejected,
                    RentalStatusEnum.applicationExpired,
                    RentalStatusEnum.applicationRejected,
                    RentalStatusEnum.appliedNotVerified,
                    RentalStatusEnum.cancelled,
                    RentalStatusEnum.completed
                  ].includes(status) ? (
                    <Link
                      href={`tel:${formatPhone(
                        get(data, "rental.driver.user.phone", "")
                      )}`}
                      underline="hover"
                    >
                      {formatPhone(get(data, "rental.driver.user.phone", ""))}
                    </Link>
                  ) : null
                }
                info={`Member since ${moment(
                  get(data, "rental.driver.createdAt")
                ).format("MMM YYYY")}`}
                imageUrl={get(data, "rental.driver.user.profilePhoto.url")}
                alt="Driver"
                marginBottom
                action={
                  <Button
                    color="primary"
                    endIcon={<ChevronRightIcon color="primary" />}
                    onClick={openDriverProfile}
                  >
                    View Driver
                  </Button>
                }
              />
              <DrawerInfoCard
                overline="Car"
                title={`${get(data, "rental.car.year")} ${get(
                  data,
                  "rental.car.make"
                )} ${get(data, "rental.car.model")}`}
                subtitle={`${get(data, "rental.car.vin")}`}
                info={`Rental Protection Plan: ${
                  carProtectionPlan
                    ? snakeToProperCase(carProtectionPlan)
                    : "None"
                }`}
                imageUrl={get(data, "rental.car.photos[0].file.url")}
                alt="Car"
                marginBottom
                action={
                  <Button
                    color="primary"
                    endIcon={<ChevronRightIcon color="primary" />}
                    onClick={handleShowListingDrawer}
                  >
                    View Car
                  </Button>
                }
              />
              <OptionCard
                title={`${moment(droppedOffAt ? droppedOffAt : endDate).diff(
                  startDate,
                  "days"
                )} day rental`}
                marginBottom
              >
                <BasicTable
                  data={{
                    "Check-In": moment(startDate).format(checkInFormat),
                    "Check-Out": `${moment(
                      droppedOffAt ? droppedOffAt : endDate
                    ).format(checkInFormat)}`
                  }}
                />
              </OptionCard>
              {status === RentalStatusEnum.applied && (
                <OptionCard title="Earnings Summary" marginBottom>
                  <BasicTable
                    data={{
                      "Base Price": `$${centsToDollars(grossEarningsInCents)}`,
                      [`Protection Fee (${
                        carProtectionPlan
                          ? snakeToProperCase(carProtectionPlan)
                          : "None"
                      })`]: `-$${centsToDollars(totalProtectionPlanFeeInCents)}`
                    }}
                  />
                  <Divider className={classes.divider} />
                  <Typography
                    component="h6"
                    variant="h6"
                    color="primary"
                    align="right"
                  >
                    {`$${centsToDollars(netEarningsInCents)}`}
                  </Typography>
                </OptionCard>
              )}
              {status === RentalStatusEnum.applied && (
                <Field
                  name="rentalAgreement"
                  className={classes.checkbox}
                  component={FormCheckbox}
                  label={
                    <Typography component="">
                      I agree to the{" "}
                      <Link
                        href={get(data, "rental.prospectiveRentalContractUrl")}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="hover"
                      >
                        Vehicle Rental Agreement
                      </Link>
                    </Typography>
                  }
                />
              )}
            </DrawerPaddingContainer>
            {[
              RentalStatusEnum.active,
              RentalStatusEnum.archived,
              RentalStatusEnum.completed,
              RentalStatusEnum.late,
              RentalStatusEnum.pendingInsurance,
              RentalStatusEnum.pendingPickup
            ].includes(status) && (
              <>
                <Divider />
                <DrawerPaddingContainer>
                  <Typography component="h6" variant="h6">
                    Manage Rental
                  </Typography>
                </DrawerPaddingContainer>
                {![
                  RentalStatusEnum.completed,
                  RentalStatusEnum.applicationAutoRejected,
                  RentalStatusEnum.applicationExpired,
                  RentalStatusEnum.applicationRejected,
                  RentalStatusEnum.cancelled,
                  RentalStatusEnum.completed
                ].includes(status) && (
                  <DrawerManageRow
                    icon={<AutorenewIcon color="primary" />}
                    actionText={`Allow Extensions: ${checked ? "ON" : "OFF"}`}
                    subtitle={
                      checked
                        ? "Drivers can automatically extend their rental period."
                        : "Drivers must request to extend their rental."
                    }
                    action={
                      <Switch
                        color="primary"
                        checked={checked}
                        onChange={handleToggleExtensions}
                      />
                    }
                  />
                )}
                {status === RentalStatusEnum.pendingInsurance && (
                  <DrawerManageRow
                    icon={<AssignmentOutlinedIcon color="primary" />}
                    actionText="Temporary Rental Contract"
                    href={get(data, "rental.prospectiveRentalContractUrl")}
                    fullWidthButton
                  />
                )}
                {[
                  RentalStatusEnum.active,
                  RentalStatusEnum.late,
                  RentalStatusEnum.pendingPickup,
                  RentalStatusEnum.pendingInsurance
                ].includes(status) && (
                  <DrawerManageRow
                    icon={<CarSwitchIcon color="primary" />}
                    actionText="Switch Cars"
                    onClick={
                      status !== RentalStatusEnum.late
                        ? sendToCarSwitch
                        : sendToCarSwitchLateRentalUnavailable
                    }
                    fullWidthButton
                  />
                )}
                {[RentalStatusEnum.active, RentalStatusEnum.late].includes(
                  status
                ) && (
                  <DrawerManageRow
                    icon={<CreateIcon color="primary" />}
                    actionText="Lower Daily Price"
                    onClick={sendToChangeActiveDailyPrice}
                    fullWidthButton
                  />
                )}
                {[
                  RentalStatusEnum.active,
                  RentalStatusEnum.completed,
                  RentalStatusEnum.late,
                  RentalStatusEnum.pendingPickup
                ].includes(status) && (
                  <DrawerManageRow
                    icon={<AssignmentOutlinedIcon color="primary" />}
                    actionText="Rental Documents"
                    onClick={sendToRentalContracts}
                    fullWidthButton
                  />
                )}
                {[
                  RentalStatusEnum.active,
                  RentalStatusEnum.completed,
                  RentalStatusEnum.late
                ].includes(status) && (
                  <DrawerManageRow
                    icon={<CollectionsIcon color="primary" />}
                    actionText="Rental Images"
                    onClick={sendToRentalImages}
                    fullWidthButton
                  />
                )}
                <EarningsTotalSection
                  rentalOverviewData={rentalOverviewData}
                  scopes={scopes}
                  status={status}
                />
              </>
            )}
            {status === RentalStatusEnum.applied && (
              <DrawerBottomBar
                onCancel={handleRejectBooking}
                cancelText={rejecting ? "Rejecting" : "Reject"}
                onSubmit={handleSubmit}
                submitText={requiresAch ? "Continue" : "Accept"}
                disabled={submitting || rejecting}
              />
            )}
            {status === RentalStatusEnum.pendingPickup && (
              <DrawerBottomBar
                onSubmit={() => {
                  history.push(`${RouteEnum.checkIn}/${id}`);
                }}
                submitText="Check-In"
              />
            )}
            {[RentalStatusEnum.active, RentalStatusEnum.late].includes(
              status
            ) && (
              <DrawerBottomBar
                submitText="Check-Out"
                onSubmit={sendToCheckOut}
              />
            )}
            {status === RentalStatusEnum.completed &&
              calculateTimePastCompleted(droppedOffAt) <= 24 && (
                <DrawerBottomBar
                  tutorialId={joyrideTutorialIds.fileClaimButton}
                  submitText="File a claim"
                  onSubmit={() => {
                    history.push(RouteEnum.claims, { id });
                  }}
                />
              )}
            {status === RentalStatusEnum.completed &&
              calculateTimePastCompleted(droppedOffAt) > 24 && (
                <DrawerBottomBar submitText="Exit" onSubmit={handleClose} />
              )}
          </>
        )}
      </>
    );
  }
);

export const RentalDrawerSummary = ({
  handleClose,
  sendToRentalContracts,
  sendToRentalImages,
  sendToEarningsBreakdown,
  sendToCheckOut,
  payoutData,
  sendToCarSwitch,
  sendToCarSwitchLateRentalUnavailable,
  sendToChangeActiveDailyPrice
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [showAchForm, setShowAchForm] = useState(false);
  const { setRooftopTos } = useContext(TosContext);
  const {
    currentRooftopClient
    //currentOrganizationClient
  } = useContext(ClientFactoryContext);

  const { data } = useQuery(CURRENT_USER_QUERY, {
    client: currentRooftopClient
  });
  const viewer = data?.viewer;
  // const { data: rooftopData } = useQuery(ROOFTOP_INFO, {
  //   variables: {
  //     id: jwtDecode(currentRooftopToken).id
  //   },
  //   client: currentOrganizationClient
  // });
  const { data: rentalData } = useQuery(RENTAL_REQUEST_QUERY, {
    variables: {
      id
    },
    fetchPolicy: "network-only",
    client: currentRooftopClient,
    context: {
      important: true
    }
  });

  const requiresAch =
    get(
      payoutData,
      "getPayoutAccountInfo.payoutAccounts.stripePayoutInformation",
      null
    ) === null;
  // const rooftopAddress = get(rooftopData, "rooftop.address.streetAddress");
  // const legacyRooftopAddress = get(viewer, "me.location.formattedAddress");
  // const rentalStatus = get(rentalData, "rental.status");

  const handleShowAchForm = () => setShowAchForm(true);
  const handleCloseAchForm = () => setShowAchForm(false);

  const ownerId = viewer?.me?.owner?.id;

  return (
    <AchFormController
      onSubmitSuccess={() =>
        enqueueSnackbar("ACH Information successfully updated!", {
          variant: "success"
        })
      }
      onSubmitFail={e =>
        enqueueSnackbar(
          e.message
            ? e.message
            : "Sorry, something went wrong while updating ACH Information.",
          {
            variant: "error"
          }
        )
      }
    >
      {achFormControllerProps => (
        <ApproveBookingFormController
          ownerId={ownerId}
          rentalId={id}
          userId={get(viewer, "id")}
          driverId={get(rentalData, "rental.driver.id")}
          handleTosError={() => setRooftopTos(true)}
        >
          {approveBookingFormControllerProps => {
            const onSubmit = async values => {
              await achFormControllerProps.onSubmit(values);
              await approveBookingFormControllerProps.onSubmit(values);
              handleCloseAchForm();
            };

            const onSubmitFail = errors => scrollErrorIntoView(errors);

            return showAchForm ? (
              <AchDrawerForm
                onSubmit={onSubmit}
                validate={achFormControllerProps.validate}
                onClose={handleCloseAchForm}
              />
            ) : (
              // Commenting out until Product decides to bring it back with solution for HC4B

              // rentalStatus === RentalStatusEnum.applied &&
              //   (!rooftopAddress || !legacyRooftopAddress) ? (
              //   <UpdateRooftopAddressFormController>
              //     {updateRooftopAddressFormControllerProps => {
              //       const onSubmit = async values => {
              //         await updateRooftopAddressFormControllerProps.onSubmit(
              //           values
              //         );
              //         handleClose();
              //       };
              //       return (
              //         <OwnerAddressDrawerForm
              //           onClose={handleClose}
              //           onSubmit={onSubmit}
              //         />
              //       );
              //     }}
              //   </UpdateRooftopAddressFormController>
              // ) :
              <>
                <RentalDrawerSummaryForm
                  onSubmit={
                    requiresAch
                      ? handleShowAchForm
                      : approveBookingFormControllerProps.onSubmit
                  }
                  ownerId={ownerId}
                  handleClose={handleClose}
                  sendToRentalContracts={sendToRentalContracts}
                  sendToRentalImages={sendToRentalImages}
                  sendToEarningsBreakdown={sendToEarningsBreakdown}
                  sendToCarSwitchLateRentalUnavailable={
                    sendToCarSwitchLateRentalUnavailable
                  }
                  sendToCheckOut={sendToCheckOut}
                  sendToCarSwitch={sendToCarSwitch}
                  requiresAch={requiresAch}
                  validate={approveBookingFormControllerProps.validate}
                  destroyOnUnmount={!requiresAch}
                  currentUser={viewer}
                  sendToChangeActiveDailyPrice={sendToChangeActiveDailyPrice}
                  onSubmitFail={onSubmitFail}
                />
              </>
            );
          }}
        </ApproveBookingFormController>
      )}
    </AchFormController>
  );
};
