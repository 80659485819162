import React, { useState } from "react";
import PropTypes from "prop-types";

export const DriverProfileContext = React.createContext();

export const DriverProfileProvider = ({ children }) => {
  const [driverId, setDriverId] = useState(null);
  const [driverUserId, setDriverUserId] = useState(null);

  const onDrawerOpen = (driverIdParam, driverUserIdParam) => {
    setDriverId(driverIdParam);
    setDriverUserId(driverUserIdParam);
  };

  const onDrawerClose = () => {
    setDriverId(null);
    setDriverUserId(null);
  };

  return (
    <DriverProfileContext.Provider
      value={{
        driverId,
        driverUserId,
        onDrawerOpen,
        onDrawerClose
      }}
    >
      {children}
    </DriverProfileContext.Provider>
  );
};

DriverProfileProvider.propTypes = {
  children: PropTypes.node
};
