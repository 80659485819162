import React, { useState, useEffect } from "react";
import { InstantSearch, Configure } from "react-instantsearch/dom";
import get from "lodash/get";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Hidden from "@mui/material/Hidden";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { MarketplaceTable } from "./MarketplaceTable";
import { MarketplacePagination } from "./MarketplacePagination";
import { MarketplaceMap } from "./MarketplaceMap";
import { MarketplaceFilters } from "./MarketplaceFilters";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { useGeolocation } from "Components/hooks/useLocation";
import { useCurrentUser } from "Components/hooks/useCurrentUser";
import { Chimes } from "Components/Loading/Chimes";
import { ButtonRouter } from "Components/Buttons/ButtonRouter";
import config from "Config/config";
import { disableHeader, enableHeader } from "Redux/Header/ActionCreators";
import { MarketplaceHeader } from "Components/Header/MarketplaceHeader";

const useStyles = makeStyles(theme => ({
  borderRight: {
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      borderRight: 0
    }
  },
  paper: {
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
      borderRadius: 0,
      transition: "unset"
    }
  },
  backToLink: {
    marginBottom: theme.spacing(1)
  }
}));

export const METERS_PER_MILE = 1609.34;

export const SORT_BY_ENUM = {
  relevance: "offers-prioritized",
  lowToHigh: "offers-price_ASC",
  highToLow: "offers-price_DESC",
  distance: "offers-distance"
};

const Marketplace = ({ history, disableHeader, enableHeader }) => {
  const classes = useStyles();
  const { user, loading } = useCurrentUser();
  const { loading: loadingForLocation, location } = useGeolocation(user);
  const [locationFilterValue, setLocationFilterValue] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [radius, setRadius] = useState(50);
  const [selected, setSelected] = useState(null); //hit.id
  const [scrollPosition, setScrollPosition] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dailyFrom = 2000;
  const dailyTo = 15000;

  const handleLocationUpdate = location => setLocationFilter(location);
  const handleRadiusUpdate = radius => setRadius(radius);
  const handleSelectedUpdate = (hitId, rowId) => {
    setSelected(hitId);
    if (typeof rowId == "number") {
      const marketplace_list_height = 73;
      const pos = rowId === 0 ? 0 : marketplace_list_height * (rowId - 1);
      setScrollPosition(pos);
    }
  };
  const handleChangePage = (event, newPage) => setPage(newPage);
  const resetPage = () => setPage(0);
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (matches) {
      disableHeader();
    } else {
      enableHeader();
    }

    return () => {
      enableHeader();
    };
  }, [matches, enableHeader, disableHeader]);

  return (
    <DashboardLayout fullScreen={matches}>
      {loading || loadingForLocation ? (
        <Chimes loadingText="Loading Marketplace..." />
      ) : (
        <InstantSearch
          appId={config.algolia.appId}
          apiKey={config.algolia.apiKey}
          indexName="offers-prioritized"
        >
          {locationFilter.lat && locationFilter.lng ? (
            <Configure
              aroundLatLng={`${locationFilter.lat},${locationFilter.lng}`}
              aroundRadius={Math.round((radius || 50) * METERS_PER_MILE)}
              page={page}
              hitsPerPage={rows}
              filters={`dailyPriceInCents:${dailyFrom} TO ${dailyTo}`}
            />
          ) : (
            <Configure
              aroundLatLngViaIP={true}
              aroundRadius={Math.round((radius || 50) * METERS_PER_MILE)}
              page={page}
              hitsPerPage={rows}
            />
          )}
          {matches ? (
            <MarketplaceHeader history={history} />
          ) : (
            <ButtonRouter
              to="/fleet/dashboard/resources"
              color="primary"
              className={classes.backToLink}
            >
              &lt; back to resources
            </ButtonRouter>
          )}
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item md={5} xs={12} className={classes.borderRight}>
                <MarketplaceFilters
                  handleLocationUpdate={location => {
                    setLocationFilterValue(location.address);
                    if (location.lat && location.lng) {
                      setLocationFilter(location);
                    }
                  }}
                  locationFilter={locationFilterValue}
                  resetPage={resetPage}
                />
                <MarketplaceTable
                  selected={selected}
                  handleSelectedUpdate={handleSelectedUpdate}
                  location={location}
                  position={scrollPosition}
                />
                <MarketplacePagination
                  rows={rows}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                />
              </Grid>
              <Hidden mdDown>
                <Grid item md={7}>
                  <div style={{ width: "100%", height: "721px" }}>
                    <MarketplaceMap
                      lat={
                        locationFilter
                          ? get(locationFilter, "lat", null)
                          : get(location, "lat", null)
                      }
                      lng={
                        locationFilter
                          ? get(locationFilter, "lng", null)
                          : get(location, "lng", null)
                      }
                      radius={radius}
                      handleRadiusUpdate={handleRadiusUpdate}
                      handleLocationUpdate={handleLocationUpdate}
                      selected={selected}
                      handleSelectedUpdate={handleSelectedUpdate}
                    />
                  </div>
                </Grid>
              </Hidden>
            </Grid>
          </Paper>
        </InstantSearch>
      )}
    </DashboardLayout>
  );
};

export const MarketplaceView = compose(
  connect(null, {
    disableHeader,
    enableHeader
  }),
  withRouter
)(Marketplace);
