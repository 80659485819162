import axios from "axios";
import qs from "query-string";
import jwtDecode from "jwt-decode";
import moment from "moment";
import get from "lodash/get";
import { WebAuth } from "auth0-js";
import AsyncLock from "async-lock";

import config from "Config/config";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

import { trackLeadReferrer } from "Analytics/index";

const lock = new AsyncLock();

const LoginErrorEnum = {
  exists: "user does not exist in repository with user_name provided"
};
class AuthController {
  constructor() {
    this.subscribe = new Promise(resolve => (this.subscribeResolve = resolve));
    this.init();
    this.handleRedirects();
  }

  init() {
    const authInfo = this.getAuthInfoFromLocalStorage();
    if (authInfo && authInfo.id_token) {
      const { id_token } = authInfo;
      this.id_token = id_token;

      trackLeadReferrer(id_token);
    }
  }

  handleRedirects() {
    this.ssoRedirectHandler()
      .then(() => {
        return this.auth0RedirectHandler();
      })
      .then(() => {
        this.subscribeResolve();
      });
  }

  refreshToken() {
    return lock.acquire("refreshTokenLock", async () => {
      if (this.isIdTokenValid()) {
        return;
      } else {
        const refresh_token = get(
          this.getAuthInfoFromLocalStorage(),
          "refresh_token",
          null
        );

        if (refresh_token) {
          const {
            token_endpoint,
            refresh_token_grant_type,
            client_id,
            client_secret
          } = config.gluu;

          const body = {
            grant_type: refresh_token_grant_type,
            refresh_token,
            client_secret,
            client_id
          };

          const resp = await axios.post(token_endpoint, qs.stringify(body), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Basic ${btoa(`${client_id}:${client_secret}`)}`
            },
            withCredentials: true
          });

          if (
            resp.status === 200 &&
            resp.data &&
            resp.data.access_token &&
            resp.data.id_token
          ) {
            this.handleTokenData(resp.data);
            return resp;
          } else {
            throw new Error(
              "AuthController: There was an error refreshing the token."
            );
          }
        } else {
          return null;
        }
      }
    });
  }

  trackError(error) {
    analytics.track(AnalyticsEvents.label.auth.loginFailed.legacyEventName, {
      category: AnalyticsEvents.category.deviceNetworking,
      action: AnalyticsEvents.action.requestFailed,
      label: AnalyticsEvents.label.auth.loginFailed.label,
      property: JSON.stringify({
        provider: "email",
        flow: AnalyticsEvents.flow.login,
        error
      }),
      value: "",
      context: ""
    });
  }

  async login(customerServicePhoneNumber, username, password) {
    const {
      token_endpoint,
      grant_type,
      client_id,
      client_secret,
      scope
    } = config.gluu;

    const body = {
      grant_type,
      scope,
      username,
      password
    };

    let resp;
    try {
      resp = await axios.post(token_endpoint, qs.stringify(body), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${btoa(`${client_id}:${client_secret}`)}`
        },
        withCredentials: true,
        validateStatus: status =>
          (status >= 200 && status < 300) || status === 401
      });
    } catch (error) {
      this.trackError(error?.message);
      throw error;
    }

    if (
      resp.status === 200 &&
      resp.data &&
      resp.data.access_token &&
      resp.data.id_token
    ) {
      this.handleTokenData(resp.data);
      return resp;
    } else {
      this.trackError(resp?.data?.error);
      throw new Error(
        resp.data && resp.data.error
          ? this.transformLoginError(resp.data.error)
          : "Either the email or password is incorrect- please try again.\n" +
            `For further assistance please reach out to our support team ${customerServicePhoneNumber}`
      );
    }
  }

  loginWithAuth0(username, password) {
    return new Promise((_, reject) => {
      const webAuth = new WebAuth({
        domain: config.auth0.domain,
        clientID: config.auth0.clientID,
        redirectUri: config.gluu.redirect_uri,
        audience: config.auth0.audience,
        responseType: "token id_token"
      });

      webAuth.login(
        {
          username: username.trim(),
          password: password.trim(),
          realm: config.auth0.connection
        },
        reject
      );
    });
  }

  auth0RedirectHandler() {
    return new Promise(resolve => {
      const webAuth = new WebAuth({
        domain: config.auth0.domain,
        clientID: config.auth0.clientID,
        redirectUri: config.gluu.redirect_uri,
        audience: config.auth0.audience,
        responseType: "token id_token"
      });

      webAuth.parseHash((_, result) => {
        if (result && result.accessToken) {
          this.handleTokenData({
            access_token: result.accessToken,
            expires_in: result.expiresIn,
            id_token: result.accessToken,
            refresh_token: result.refreshToken,
            scope: result.scope
          });
        }

        resolve();
      });
    });
  }

  async loginWithApple() {
    const queryStringData = {
      scope: config.gluu.scope,
      acr_values: config.gluu.acr_values,
      response_type: "code",
      redirect_uri: config.gluu.redirect_uri,
      client_id: config.gluu.client_id,
      preselectedExternalProvider: config.gluu.apple
    };
    const url = `${config.gluu.sso_uri}?${qs.stringify(queryStringData)}`;
    window.location.replace(url);
  }

  async loginWithFacebook() {
    const queryStringData = {
      scope: config.gluu.scope,
      acr_values: config.gluu.acr_values,
      response_type: "code",
      redirect_uri: config.gluu.redirect_uri,
      client_id: config.gluu.client_id,
      preselectedExternalProvider: config.gluu.facebook
    };
    const url = `${config.gluu.sso_uri}?${qs.stringify(queryStringData)}`;
    window.location.replace(url);
  }

  async loginWithGoogle() {
    const queryStringData = {
      scope: config.gluu.scope,
      acr_values: config.gluu.acr_values,
      response_type: "code",
      redirect_uri: config.gluu.redirect_uri,
      client_id: config.gluu.client_id,
      preselectedExternalProvider: config.gluu.google
    };
    const url = `${config.gluu.sso_uri}?${qs.stringify(queryStringData)}`;
    window.location.replace(url);
  }

  logout() {
    this.setIdToken(null);
    this.clearCache();
    const OAuthLogin = window.localStorage.getItem("social-login");
    if (OAuthLogin) {
      const url = `${
        config.gluu.sso_logout_uri
      }?&post_logout_redirect_uri=${encodeURIComponent(
        config.gluu.sso_post_logout_uri
      )}`;
      window.localStorage.removeItem("social-login");
      window.location.replace(url);
    }
  }

  async ssoRedirectHandler() {
    const parsedQueryString = qs.parse(window.location.search);

    if (parsedQueryString.code && parsedQueryString.code !== "") {
      await this.getTokenWithCode(parsedQueryString.code);
    }
  }

  async getClientAccessToken() {
    const { token_endpoint, client_id, client_secret } = config.gluu;

    try {
      const { data } = await axios.post(
        token_endpoint,
        qs.stringify({
          grant_type: "client_credentials",
          scope: "storage"
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${btoa(`${client_id}:${client_secret}`)}`
          }
        }
      );

      return data.access_token;
    } catch (e) {
      console.log(e);
      throw new Error(
        "AuthController: authenticateClient: Authentication Failed."
      );
    }
  }

  async getTokenWithCode(code) {
    const {
      token_endpoint,
      auth_code_grant_type,
      client_id,
      client_secret,
      redirect_uri
    } = config.gluu;

    const body = {
      grant_type: auth_code_grant_type,
      code,
      client_id,
      redirect_uri
    };

    const resp = await axios.post(token_endpoint, qs.stringify(body), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${btoa(`${client_id}:${client_secret}`)}`
      },
      withCredentials: true
    });

    if (
      resp.status === 200 &&
      resp.data &&
      resp.data.access_token &&
      resp.data.id_token
    ) {
      this.handleTokenData(resp.data);
      const { acr, auth0Id, driver, id, owner } = JSON.parse(
        atob(get(resp, "data.id_token").split(".")[1])
      );
      if (acr !== "passport_social" || !id) return;
      analytics.track(AnalyticsEvents.label.auth.socialLogin, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.webConversion,
        label: AnalyticsEvents.label.auth.socialLogin,
        property: JSON.stringify({
          driverId: get(driver, "id"),
          ownerId: get(owner, "id"),
          inum: auth0Id
        }),
        value: "",
        context: ""
      });
      return resp;
    } else {
      throw new Error("AuthController: Authentication Failed.");
    }
  }

  handleTokenData(data) {
    this.cacheAuthInfo(data);
    this.setIdToken(data.id_token);
    this.setSnowplowContext(data.id_token);
  }

  cacheAuthInfo({ access_token, expires_in, id_token, refresh_token, scope }) {
    window.localStorage.setItem(
      "auth_info",
      JSON.stringify({
        access_token,
        expires_in,
        id_token,
        refresh_token,
        scope
      })
    );
    window.localStorage.setItem("id_token", id_token);
  }

  getIdTokenFromLocalStorage() {
    return window.localStorage.getItem("id_token");
  }

  getAuthInfoFromLocalStorage() {
    return JSON.parse(window.localStorage.getItem("auth_info"));
  }

  setIdToken(id_token) {
    this.id_token = id_token;
  }

  setSnowplowContext(id_token) {
    // Snowplow picks this up within other sub-domains
    try {
      document.cookie = `hc-canon-uid=${
        JSON.parse(atob(id_token.split(".")[1])).id
      }`;
    } catch (e) {
      console.error("error setting sp context", e.message);
    }
  }

  getIdToken() {
    return this.id_token;
  }

  isIdTokenValid() {
    if (this.id_token) {
      const decoded = jwtDecode(this.id_token);
      /* validate token is not expired and token contains user id */
      const { exp, id } = decoded;
      if (
        id &&
        exp &&
        moment
          .unix(exp)
          .subtract(10, "seconds")
          .isSameOrAfter(moment())
      ) {
        return true;
      }
    }

    return false;
  }

  clearCache() {
    window.localStorage.removeItem("auth_info");
    window.localStorage.removeItem("id_token");
  }

  clearIdTokenCache() {
    window.localStorage.removeItem("id_token");
    this.setIdToken(null);
  }

  getParsedToken() {
    return jwtDecode(this.id_token);
  }

  transformLoginError(error) {
    if (error === LoginErrorEnum.exists) {
      return "This user does not exist. Please sign up.";
    } else {
      return "You've entered an incorrect email and/or password. Please try again.";
    }
  }
}

export const authController = new AuthController();
