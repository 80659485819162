import { OverpricedCarsContext } from "Components/Utils/OverpricedCarsProvider";
import moment from "moment";
import { useContext, useEffect } from "react";

import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { checkAllPermissions } from "Utils/RooftopUtils";
import { CarsDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useOverpricedCarAlert = () => {
  const { overpricedCars, priceAlertResponse } = useContext(
    OverpricedCarsContext
  );

  const { scopes } = useContext(ClientFactoryContext);

  const isRooftopUser = checkAllPermissions(
    [CarsDomainPermissionPaths.manualRooftopSelect],
    scopes
  );

  useEffect(() => {
    if (overpricedCars?.data)
      analytics.track(AnalyticsEvents.label.owner.carOverpricedAlert, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.alertShown,
        label: AnalyticsEvents.label.owner.carOverpricedAlert,
        property: JSON.stringify({
          overpricedCars: overpricedCars.data,
          marketingOrigin: null
        }),
        value: "",
        context: ""
      });
  }, [overpricedCars]);

  const hasOverpricedCars =
    overpricedCars?.data?.filter(car => car.overpriced).length > 0;

  const alertUnmuteDate =
    priceAlertResponse?.priceAlertInfo?.alertUnmuteDate &&
    moment(priceAlertResponse?.priceAlertInfo?.alertUnmuteDate);

  const userHasUnmutedDate = !!priceAlertResponse?.priceAlertInfo?.id;

  const showAlert =
    !isRooftopUser &&
    hasOverpricedCars &&
    (!userHasUnmutedDate || alertUnmuteDate?.isSameOrBefore(moment()));

  const firstOverpricedCar = overpricedCars?.data?.find(car => car.overpriced);

  return {
    firstOverpricedCar,
    showAlert,
    priceAlertResponse
  };
};

export default useOverpricedCarAlert;
