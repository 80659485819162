import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";

import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";
import { openSupportPage } from "Utils/openSupportPage";
import { CAR_SUMMARY } from "Queries/Car/Car";
import { OWNER_DEFAULT_PROTECTION_PLAN } from "Queries/Owner/OwnerQueries";
import { DrawerCloseButton } from "Components/Drawers/DrawerCloseButton";
import { DrawerHead } from "Components/Drawers/DrawerHead";
import {
  DrawerAction,
  DrawerActionContainer
} from "Components/Drawers/DrawerAction";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { OptionCard } from "Components/Cards/OptionCard";
import { DrawerManageRow } from "Components/Drawers/DrawerManageRow";
import { BasicTable } from "Components/Tables/BasicTable";
import {
  centsToDollars,
  calculateWeeklyDiscount,
  calculateMonthlyDiscount
} from "Utils/Calculations";
import { CarStatusEnum } from "Enums/StateEnums";
import { ownerGetCarDeepLink as getCarDeepLink } from "Utils/BranchHelper";
import { useListingStatusOptions } from "Components/hooks/useListingStatusOptions";
import { ListingsDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { renderByPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { snakeToProperCase } from "Utils/Helpers";
import { ListingPhotoSlideShow } from "./ListingPhotoSlideShow";
import { OverpricedCarsContext } from "Components/Utils/OverpricedCarsProvider";

const useStyles = makeStyles(theme => ({
  image: {
    display: "block",
    width: "100%",
    height: "240px",
    objectFit: "cover"
  },
  text: {
    color: theme.palette.common.white
  }
}));

const CAR_IMAGE_PLACEHOLDER_URL =
  "https://d1faaofv0bplxp.cloudfront.net/car_placeholder_image.svg";

const parsePrimaryCarPhoto = data => {
  const photos = data?.car?.photos;
  if (!photos || photos.length < 1) {
    return CAR_IMAGE_PLACEHOLDER_URL;
  } else {
    const photo = photos.find(photo => photo?.isPrimary);
    return photo?.file?.url;
  }
};

export const ListingSummary = ({
  handleClose,
  sendToListingProtectionPlan,
  sendToListingEdit,
  sendToListingStatusEdit,
  carId,
  useBackButton
}) => {
  const classes = useStyles();
  const [copyLink, setCopyLink] = useState(null);
  const { scopes, currentRooftopClient } = useContext(ClientFactoryContext);
  const { isCarOverpriced } = useContext(OverpricedCarsContext);
  const { enqueueSnackbar } = useSnackbar();
  const { id: paramsId } = useParams();
  const id = carId ? carId : paramsId;

  const { data, loading } = useQuery(CAR_SUMMARY, {
    variables: {
      id
    },
    fetchPolicy: "network-only",
    client: currentRooftopClient
  });
  const { data: ownerDefaultProtectionPlanData } = useQuery(
    OWNER_DEFAULT_PROTECTION_PLAN,
    {
      fetchPolicy: "cache-only",
      client: currentRooftopClient
    }
  );
  const car = get(data, "car", {});
  const ownerDefaultProtectionPlan = get(
    ownerDefaultProtectionPlanData,
    "viewer.me.owner.defaultProtectionPlan",
    null
  );
  const carImageUrl = parsePrimaryCarPhoto(data);
  const listingData = {
    "Daily Price": `$${centsToDollars(car.dailyPriceInCents)}`,
    "Weekly Discount": `${calculateWeeklyDiscount(
      car.dailyPriceInCents,
      car.weeklyPriceInCents
    )}%`,
    "Monthly Discount": `${calculateMonthlyDiscount(
      car.dailyPriceInCents,
      car.monthlyPriceInCents
    )}%`,
    "Allowed Miles": `${car.maxDailyMiles}`,
    "Pickup Address": get(car, "location.formattedAddress", null)
  };
  const toggleListingDisabled =
    car.status === CarStatusEnum.rented || car.status === CarStatusEnum.deleted;
  const protectionPlanDisabled =
    car.status === CarStatusEnum.rented || car.status === CarStatusEnum.deleted;
  const editCarDisabled = car.status === CarStatusEnum.deleted;
  const { copy } = useListingStatusOptions(car.status, car.verification);

  useEffect(() => {
    (async () => {
      if (car) {
        try {
          const carDeepLink = await getCarDeepLink({
            car: {
              ...car,
              car_photo: get(car, "photos[0].file.url"),
              p2p_price_daily: get(car, "dailyPriceInCents", 0)
            },
            utmSource: "listing_summary",
            pageName: "Listings Summary"
          });
          setCopyLink(carDeepLink);
        } catch (e) {
          console.error(e);
          setCopyLink("");
        }
      }
    })();
  }, [car]);

  const listingStatusMessage = copy => {
    let message = null;

    switch (copy) {
      case "Rented":
        message = "This listing is currently rented";
        break;
      case "Removed":
        message = "This listing is no longer available";
        break;
      case "Deleted":
        message = "This car is permanently deleted";
        break;
      case "Snoozed":
        message = "This car is temporarily deactivated";
        break;
      case "Live":
      default:
        message = "This car is currently active";
        break;
    }

    return (
      <>
        <div>Listing Status: {copy}</div>
        <div>{message}</div>
      </>
    );
  };

  return loading ? (
    <SkeletonDrawer showCar />
  ) : (
    <>
      <DrawerCloseButton onClick={handleClose} useBackButton={useBackButton} />
      {carImageUrl && (
        <ListingPhotoSlideShow
          className={classes.image}
          imageUrl={carImageUrl}
          photos={data?.car?.photos || []}
        />
      )}
      <DrawerHead
        overline={car.year}
        title={(car.make || car.year) && `${car.make} ${car.model}`}
      >
        {car.vin && (
          <Typography
            component="span"
            variant="subtitle1"
            className={classes.text}
          >
            {car.vin}
          </Typography>
        )}
      </DrawerHead>
      <DrawerActionContainer>
        <CopyToClipboard
          text={copyLink}
          onCopy={() =>
            enqueueSnackbar("Copied to Clipboard!", { variant: "success" })
          }
        >
          <DrawerAction
            type="button"
            icon={<ShareIcon />}
            text="Share"
            xs={4}
            rightBorder
          />
        </CopyToClipboard>
        <DrawerAction
          type="button"
          icon={<EditIcon />}
          text="Edit"
          xs={4}
          onClick={sendToListingEdit}
          disabled={editCarDisabled}
        />
        <DrawerAction
          icon={<HelpIcon />}
          text="Help"
          xs={4}
          leftBorder
          onClick={() => openSupportPage()}
        />
      </DrawerActionContainer>
      <DrawerPaddingContainer>
        <OptionCard title="Listing Details">
          <BasicTable
            data={listingData}
            isCarOverpriced={isCarOverpriced(id)}
          />
        </OptionCard>
      </DrawerPaddingContainer>
      <Divider />
      <DrawerPaddingContainer>
        <Typography component="h6" variant="h6">
          Manage Listings
        </Typography>
      </DrawerPaddingContainer>
      <DrawerPaddingContainer></DrawerPaddingContainer>
      {isCarOverpriced(id) && (
        <DrawerManageRow
          id="change-listing-status"
          onClick={() => sendToListingEdit("pricing")}
          icon={<WarningAmberIcon color="warning" />}
          actionText={
            <>
              <div>Review Car Price</div>
            </>
          }
        />
      )}
      <DrawerManageRow
        id="change-listing-status"
        onClick={sendToListingStatusEdit}
        icon={
          <OfflineBoltIcon
            color={toggleListingDisabled ? "disabled" : "primary"}
          />
        }
        disabled={toggleListingDisabled}
        actionText={listingStatusMessage(copy)}
      />
      {renderByPermissions(
        <DrawerManageRow
          id="change-protection-plan"
          onClick={sendToListingProtectionPlan}
          icon={
            <VerifiedUserIcon
              color={protectionPlanDisabled ? "disabled" : "primary"}
            />
          }
          actionText={`Protection Plan: ${
            car.defaultProtectionPlan
              ? snakeToProperCase(car.defaultProtectionPlan)
              : snakeToProperCase(ownerDefaultProtectionPlan)
          }`}
          disabled={protectionPlanDisabled}
        />,
        [ListingsDomainPermissionPaths.editProtectionPlan],
        scopes
      )}
    </>
  );
};

ListingSummary.propTypes = {
  handleClose: PropTypes.func.isRequired,
  sendToListingProtectionPlan: PropTypes.func.isRequired,
  sendToListingEdit: PropTypes.func.isRequired,
  sendToListingStatusEdit: PropTypes.func.isRequired,
  carId: PropTypes.string,
  useBackButton: PropTypes.bool
};
