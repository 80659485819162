import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { Alert } from "@mui/material";

const useStyles = makeStyles(theme => ({
  status: {
    marginBottom: "10px",
    fontWeight: "600",
    letterSpacing: "1.5px"
  },
  title: {
    lineHeight: "1.25em",
    marginBottom: "10px",
    color: theme.palette.common.black
  },
  carImg: {
    display: "flex",
    justifyContent: "flex-end",
    "& img": {
      width: "80px",
      height: "80px",
      objectFit: "cover"
    }
  },
  container: {
    cursor: "pointer"
  },
  overPriceAlert: {
    border: "none",
    marginRight: 0,
    padding: 0,
    "& .MuiAlert-icon": {
      marginRight: theme.spacing(1),
      padding: 0
    }
  }
}));

export const CarInfoHeader = ({
  title,
  carPhoto,
  status,
  details,
  to,
  statusComponent,
  isCarOverpriced,
  dailyPriceInDollars
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      spacing={2}
      onClick={() => history.push(to)}
      className={classes.container}
    >
      {statusComponent && (
        <Grid item xs={12}>
          {statusComponent}
        </Grid>
      )}
      <Grid item xs={8}>
        {status && (
          <Typography
            variant="overline"
            component="p"
            className={classes.status}
          >
            {status}
          </Typography>
        )}
        <Typography variant="h6" component="h6" className={classes.title}>
          {title}
        </Typography>
        {details.map((detail, index) => {
          return (
            <Typography variant="body1" component="p" key={index}>
              {detail}
            </Typography>
          );
        })}
        <Typography variant="body1" component="p" display="flex" mt={1}>
          {isCarOverpriced && (
            <Alert
              severity="warning"
              variant="outlined"
              className={classes.overPriceAlert}
            />
          )}
          {dailyPriceInDollars}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.carImg}>
        {carPhoto}
      </Grid>
    </Grid>
  );
};

CarInfoHeader.propTypes = {
  title: PropTypes.string,
  carPhoto: PropTypes.object,
  status: PropTypes.string,
  details: PropTypes.array,
  to: PropTypes.string,
  statusComponent: PropTypes.node
};
